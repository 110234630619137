<template>
  <div class="users">
    <v-row>
      <v-col sm="12">
        <span class="headline">{{ pageName }}</span>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col sm="3" class="ml-auto">
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search this list..." single-line></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="12">
        <v-data-table
          :headers="headers"
          :items="mps"
          :loading="loading"
          :search="search"
          :items-per-page="5"
          class="elevation-1"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="onEditUser(item)">mdi-pencil</v-icon>
            <v-icon small @click="onDeleteUser(item)">mdi-delete</v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-btn bottom color="amber" fab fixed right @click="onCreateUser">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <CreateMPS :showDialog="dialog" :users="users" @dialog-state-change="onDialogStateChange" />
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapActions } from 'vuex';
import CreateMPS from '@/components/CreateMPS.vue';
export default {
  name: 'users',
  components: {
    CreateMPS,
  },
  data() {
    return {
      pageName: 'MPS Profiles',
      dialog: false,
      search: null,
    };
  },
  computed: {
    ...mapGetters('users', { users: 'getUsers', loading: 'getLoading' }),
    headers() {
      if (this.isSystemAdmin) {
        return [
          {
            text: 'First Name',
            value: 'firstName',
          },
          {
            text: 'Last Name',
            value: 'lastName',
          },
          {
            text: 'Email',
            value: 'email',
          },
          {
            text: 'Region',
            value: 'region',
          },
          {
            text: 'Auto-Reject Exempt',
            value: 'autoRejectExempt',
          },
          {
            text: 'Auto-Urgent',
            value: 'autoUrgent',
          },
          {
            text: 'Actions',
            value: 'actions',
            sortable: false,
          },
        ];
      } else {
        return [
          {
            text: 'First Name',
            value: 'firstName',
          },
          {
            text: 'Last Name',
            value: 'lastName',
          },
          {
            text: 'Email',
            value: 'email',
          },
          {
            text: 'Region',
            value: 'region',
          },
          {
            text: 'Auto-Reject Exempt',
            value: 'autoRejectExempt',
          },
          {
            text: 'Auto-Urgent',
            value: 'autoUrgent',
          },
        ];
      }
    },
    mps() {
      return this.users.filter((user) => user.userType == 'MPS');
    },
    isSystemAdmin() {
      return this.$store.state.accounts.currentAccount.role === 'System Admin';
    },
  },
  created() {
    this.setUsers();
  },
  methods: {
    ...mapActions('users', { setUsers: 'setUsers' }),
    onDialogStateChange(state) {
      this.dialog = state;
    },
    onCreateUser() {
      this.dialog = true;
    },
    onDeleteUser(user) {
      axios.post('https://func-gcas-dev.azurewebsites.net/api/deleteUser', user).then((res) => {
        console.log('Delete user response: ', res);
        this.setUsers();
      });
    },
  },
};
</script>
