<template>
  <div class="create-user">
    <v-dialog v-model="isDialogVisible" persistent width="640px">
      <v-card>
        <v-card-title class="amber"><span class="headline">Create MPS Profile</span></v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-container>
              <v-row>
                <v-col sm="6">
                  <v-autocomplete
                    v-model="email"
                    :items="items"
                    item-text="mail"
                    item-value="mail"
                    :search-input.sync="search"
                    :rules="emailRules"
                    required
                    clearable
                    @click:clear="items = []"
                    label="E-mail"
                    return-object
                  >
                    <template v-slot:item="{ item, on, attrs }">
                      <v-list-item v-bind="attrs" v-on="on">
                        <v-list-item-content>
                          <v-list-item-title v-text="`${item.displayName}`"></v-list-item-title>
                          <v-list-item-subtitle v-text="item.mail"></v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col sm="6">
                  <v-select :items="regions" v-model="region" :rules="regionRules" required label="Region"></v-select>
                </v-col>
                <v-col sm="6" v-if="isAuthorized">
                  <v-switch v-model="autoRejectExempt" label="Auto-Reject Exempt?"></v-switch>
                </v-col>
                <v-col sm="6" v-if="isAuthorized">
                  <v-switch v-model="autoUrgent" label="Auto-Urgent?"></v-switch>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="mx-2">
          <v-spacer></v-spacer>
          <v-btn text @click="onCancel">Cancel</v-btn>
          <v-btn text color="warning" :disabled="!valid" @click="onCreate">Create</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'create-user',
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    users: {
      type: Array,
      require: false,
    },
  },
  data() {
    return {
      valid: false,
      search: null,
      items: [],
      email: '',
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => !this.checkEmail(v) || 'A profile for this user already exists',
      ],
      region: '',
      regionRules: [(v) => !!v || 'Region is required'],
      regions: ['US', 'APAC', 'SSP'],
      autoUrgent: false,
      autoRejectExempt: false,
    };
  },
  computed: {
    ...mapGetters('accounts', { currentAccount: 'getCurrentAccount' }),
    isDialogVisible: {
      get() {
        return this.showDialog;
      },
      set(value) {
        this.$emit('dialog-state-change', value);
      },
    },
    isAuthorized() {
      return ['System Admin', 'Manager / Senior Manager'].some((role) => role === this.currentAccount.role);
    },
  },
  methods: {
    ...mapActions('users', { setUsers: 'setUsers', setUser: 'setUser' }),
    async onCreate() {
      let data = {
        firstName: this.email.givenName,
        lastName: this.email.surname,
        email: this.email.mail,
        region: this.region,
        autoUrgent: this.autoUrgent ? true : false,
        autoRejectExempt: this.autoRejectExempt ? true : false,
        userType: 'MPS',
      };
      this.user = await this.setUser(data);
      this.onCancel();
    },

    onCancel() {
      this.$refs.form.reset();
      this.isDialogVisible = false;
    },
    checkEmail(email) {
      return this.users.some((user) => user.email === email.mail);
    },
    getUsers(newValue) {
      setTimeout(() => {
        axios
          .get(`https://graph.microsoft.com/v1.0/users?$search="mail:${newValue}"&$orderby=displayName&$count=true`, {
            headers: {
              authorization: `bearer ${this.$store.state.accounts.accessToken}`,
              ConsistencyLevel: 'eventual',
            },
          })
          .then((res) => {
            this.items = res.data.value;
            console.log(this.items);
          })
          .catch((err) => {
            console.log(err);
          });
      }, 1000);
    },
  },
  watch: {
    search: function(newValue) {
      console.log('Search value: ', { newValue: newValue });
      if (!newValue) {
        return;
      }
      if (newValue && newValue !== this.email && newValue.length >= 3) {
        this.getUsers(newValue);
      }
    },
    email: function(newValue, oldValue) {
      console.log('Email changed: ', { newValue: newValue, oldValue: oldValue });
      console.log('Email exists: ', this.checkEmail(newValue));
    },
  },
};
</script>

<style></style>
